<!--
 * @Author: wjc
 * @Date: 2023-11-08 15:03:03
 * @LastEditors: wjc
 * @LastEditTime: 2023-11-08 16:34:25
 * @Description: 
-->
<template>
  <div class="error-page-container">
    <MRow :span="18">
      <el-col :span="12">
        <img src="/images/404.png" />
      </el-col>
      <el-col :span="12">
        <el-space direction="vertical" alignment="normal" :size="32">
          <div class="text-primary-color font-600 font-s-200px">404</div>
          <el-space direction="vertical" alignment="normal">
            <div class="text-color-2 font-s-28px font-600">抱歉！您访问的页面不存在</div>
            <div class="text-color-place">Sorry ! the page you</div>
          </el-space>
          <span
            class="back-btn flex-none! color-white font-s-14px py-6px px-13px rounded-4px cursor-pointer"
          >
            <span @click="handleBack">返回首页</span>
          </span>
        </el-space>
      </el-col>
    </MRow>
  </div>
</template>

<script setup lang="ts">
  defineOptions({
    name: 'ErrorNotFound',
  })

  const handleBack = () => {
    clearError({
      redirect: '/',
    })
  }
</script>

<style scoped lang="scss">
  .error-page-container {
    @apply py-80px;
    .back-btn {
      background: linear-gradient(135deg, #53c461 0%, #299132 100%);
    }
  }
</style>
